import React from 'react'
import '../styles/CareerNotFound.css'
import { Link } from 'react-router-dom'
function CareerNotFound() {
    return (
        <>
            <div className="CNFcontainer">
                <svg
                    className='CNFsvg'
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="795.9"
                    height={433}
                    viewBox="0 0 795.9 433"
                >
                    <g className="page-fall2">
                        <path
                            className="white_page fade7"
                            d="M0 377.8h60.9s9.330.2 3.3 55.1H0v-55.1z"
                        />
                    </g>
                    <g className="page-fall3">
                        <path
                            className="dark_gray_page fade8"
                            d="M3.9 292.3l51-28.5s5 20.5 0 46-7.5 58.5-7.5 58.5l-24.5 25s-10-24-11.5-62-7.5-39-7.5-39z"
                        />
                    </g>
                    <g className="page-fall2">
                        <path
                            className="white_page fade7"
                            d="M11.4 174.3s11.3-19.5-11.4-70v-29l22.4-20s26 61 19.5 98.5l-30.5 20.5z"
                        />
                    </g>
                    <g className="page-fall2">
                        <path
                            className="dark_gray_page fade7"
                            d="M48.4 106.9l31-46.1s26-25 41.5-12.5l-19 27.5c0 .1-29-5.8-53.5 31.1zM236.2 429l51.5-21.5s-6.5-74-51.5-87.5l-33.5 33.5s29 37.5 33.5 75.5z"
                        />
                    </g>
                    <g className="page-fall3">
                        <path
                            className="white_page fade8"
                            d="M297.5 421.6l32-21.5s9-13.5 0-33l37-33.8s14 21.2 0 40.2c0 .1-58.5 48.6-69 48.1z"
                        />
                    </g>
                    <g className="page-fall3">
                        <g className="fade8">
                            <path
                                className="dark_gray_page"
                                d="M392.4 165.6l63 68.5s-50.5 48-63 71.5l-45.5-71.5s-8-21 45.5-68.5z"
                            />
                            <path
                                className="white_page"
                                d="M403.9 333.1s-7-1-10.5-5.5-46.5-93.5-46.5-93.5 6.5 6.5 32 4l25 95zM510.4 433s-1.5-39.1-3-41.1c0 0 64.5-9.5 87 11.5l6 29.6h-90z"
                            />
                        </g>
                    </g>
                    <g className="page-fall2">
                        <path
                            className="dark_gray_page fade7"
                            d="M486.9 274l49.2-49s52.4 49 47.5 76l-70.1 15c-.1 0-10.8-22.5-26.6-42z"
                        />
                    </g>
                    <g className="page-fall2">
                        <path
                            className="white_page fade7"
                            d="M501.5 319.5l11.9 11.1 65.7-28.6zM603.5 260.8l83.3 8.5s22.7 53.2 15.3 102.5h-86.7c.1 0 .1-86.6-11.9-111z"
                        />
                    </g>
                    <g className="page-fall2">
                        <path
                            className="white_page fade7"
                            d="M502 129.2l61.5 19.3s-3.7 12.7 0 16c3.7 3.3 1.7 28 0 31.3h-67c0 .1 2.3-62.6 5.5-66.6zM749.5 296.8l3.3 99s20-8.3 43-6.1v-92.9c.1 0-18.9-3.6-46.3 0z"
                        />
                    </g>
                    <g className="page-fall2">
                        {" "}
                        <path
                            className="medium_gray fade7"
                            d="M795.9 103.8h-34.3s8.7 14.7 8 32c-.7 17.3 3.2 85.5 3.2 85.5H796V103.8z"
                        />
                    </g>


                    <g id="comp-big">
                        <g className="fade8">
                            <path
                                className="medium_gray"
                                d="M600.1 231.8l10.8-130.7 130.7 9.3s-28.7 160-34.7 165.3l-106.8-43.9z"
                            />
                            <path
                                className="da"
                                d="M699.1 200.3c-.2 1.1-1.2 1.9-2.2 1.6l-62.5-13.5c-1-.2-1.6-1.3-1.4-2.4l10.1-46.5c.2-1.1 1.2-1.9 2.2-1.6l62.5 13.5c1 .2 1.6 1.3 1.4 2.4l-10.1 46.5z"
                            />
                            <path
                                transform="rotate(-77.79 671.109 169.92)"
                                className="light-blue"
                                d="M649.5 139.9h43.3v60h-43.3z"
                            />
                            <path
                                className="med-blue"
                                d="M695.7 200.6l14.2 3.1-.8 3.8s-3 1.8-4.6 1.5l-80.9-17.5c-1.6-.3-3.6-3.2-3.6-3.2l.8-3.8 14.2 3.1 60.7 13z"
                            />
                            <g>
                                <path
                                    className="light-green"
                                    d="M665.7 161c-.2.4-.5.7-.7 1.1l-5.3-1.1c.3-.4.6-.7 1-1.1l5 1.1zM671.6 162.3l-.3 1.2-5-1.1c.2-.4.5-.8.7-1.1l4.6 1zM677.2 164.8l-4.6-1 .3-1.2 4.2.9c0 .4.1.8.1 1.3zM684.2 166.3l-5.7-1.2c0-.5-.1-.9-.1-1.3l5.4 1.2c.1.3.3.8.4 1.3z"
                                />
                                <g>
                                    <path
                                        className="light-green"
                                        d="M662.2 176.1l-4.8-1c-.2-.5-.3-.9-.4-1.4l5.2 1.1c-.1.4 0 .8 0 1.3zM668.6 176.2l-.3 1.2-4.8-1c-.1-.4-.1-.9-.2-1.3l5.3 1.1zM669.9 176.4l4.8 1c-.2.4-.4.8-.7 1.1l-4.4-1 .3-1.1zM681.6 179c-.3.4-.6.7-1 1.1l-5.3-1.1c.2-.4.4-.8.7-1.1l5.6 1.1z"
                                    />
                                </g>
                                <g>
                                    <path
                                        className="light-green"
                                        d="M662.5 168.9l-5.8-1.3c0-.2.1-.4.1-.6 0-.2.1-.4.1-.6l5.9 1.3-.3 1.2zM670.1 169.3l-.3 1.2-6.1-1.3.3-1.2 6.1 1.3zM676.8 172l-5.7-1.2.3-1.2 5.7 1.2-.3 1.2zM684.4 173c0 .2-.1.4-.1.6l-6.3-1.4c.1-.4.2-.8.2-1.2l6.3 1.4c0 .2 0 .4-.1.6z"
                                    />
                                </g>
                                <g>
                                    <path
                                        className="green"
                                        d="M673.9 155c-8.3-1.8-16.5 3.5-18.3 11.8s3.5 16.5 11.8 18.3c8.3 1.8 16.5-3.5 18.3-11.8s-3.5-16.5-11.8-18.3zm-16.5 20c-.2-.5-.3-.9-.4-1.4-.5-1.9-.6-3.9-.3-6 0-.2.1-.4.1-.6 0-.2.1-.4.1-.6.5-2.1 1.5-3.9 2.8-5.5.3-.4.6-.7 1-1.1 2.4-2.3 5.6-3.8 9-4-1 1-2.6 2.7-4 5.1-.2.4-.5.7-.7 1.1-.1.2-.3.5-.4.8-.7 1.4-1.4 3-1.9 4.8l-.3 1.2c-.4 1.8-.5 3.7-.4 5.8 0 .4.1.9.1 1.3.2 2 .8 4.2 1.7 6.4-2.9-1.4-5.2-4.1-6.4-7.3zm9.6 8.7c-.5-.1-.9-.3-1.3-.4-1.1-2.3-1.8-4.6-2.1-6.9-.1-.4-.1-.9-.2-1.3-.2-2 0-3.9.4-5.8l.3-1.2c.4-1.5 1-3 1.7-4.5.2-.4.4-.7.6-1.1.2-.4.5-.8.7-1.1 1.9-2.9 3.9-4.8 4.5-5.3.5 0 .9.1 1.4.2l-6 27.4zm1.2.3l6-27.6.9.3c.3.6 1.4 3.2 2 6.8.1.4.1.9.2 1.3 0 .4.1.8.1 1.2.1 1.7 0 3.3-.3 4.8-.1.4-.1.8-.2 1.2-.4 1.9-1.1 3.7-2.1 5.5-.2.4-.4.8-.7 1.1-1.3 2-2.9 3.8-4.9 5.5-.4 0-.7-.1-1-.1zm13.4-5c-.3.4-.6.7-1 1.1-2.5 2.5-6 4-9.6 4.1 1.8-1.7 3.3-3.5 4.3-5.3.2-.4.4-.8.7-1.1 1-1.9 1.6-3.7 2-5.5.1-.4.2-.8.2-1.2.3-1.9.4-3.6.3-5.2 0-.3 0-.6-.1-.8 0-.5-.1-.9-.1-1.3-.4-2.8-1.1-5.1-1.6-6.4 3.3 1.6 5.7 4.3 7 7.6.2.5.3.9.5 1.4.5 1.9.7 4 .3 6.1 0 .2-.1.4-.1.6 0 .2-.1.4-.1.6-.6 1.9-1.5 3.7-2.7 5.3z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>


                    <g id="comp-little">
                        <g className="fade5">
                            <path
                                className="medium_gray"
                                d="M66.2 310.3l45.2-35.5s27.5 52.5 22 98l-52 32.6s6.6-58.1-15.2-95.1z"
                            />
                            <path
                                className="st6"
                                d="M122.8 329.7c.2.5.1 1.1-.4 1.3l-28.2 13.7c-.4.2-1 0-1.3-.5l-10.2-21c-.2-.5-.1-1.1.4-1.3l28.2-13.7c.4-.2 1 0 1.3.5l10.2 21z"
                            />
                            <path
                                transform="rotate(-25.822 102.79 326.375)"
                                className="light-blue"
                                d="M88.1 315.8h29.4v21.3H88.1z"
                            />
                            <path
                                className="med-blue"
                                d="M121.6 330.8l6.4-3.1.8 1.7s-.6 1.6-1.3 1.9L91 349c-.7.3-2.3-.2-2.3-.2l-.8-1.7 6.4-3.1 27.3-13.2z"
                            />
                            <g>
                                <path
                                    className="light-green"
                                    d="M98 324.6c0 .2.1.4.1.6l-2.4 1.2c0-.2 0-.5.1-.7l2.2-1.1zM100.7 323.3l.3.6-2.3 1.1c0-.2-.1-.4-.1-.6l2.1-1.1zM103.6 322.6l-2.1 1-.3-.6 1.9-.9.5.5zM106.8 321l-2.6 1.3c-.1-.2-.3-.3-.4-.5l2.4-1.2c.2.2.4.3.6.4z"
                                />
                                <g>
                                    <path
                                        className="light-green"
                                        d="M101.2 331.5l-2.2 1.1c-.2-.1-.4-.3-.6-.4l2.3-1.1c.2 0 .4.2.5.4zM103.7 329.6l.3.6-2.2 1.1-.5-.5 2.4-1.2zM104.3 329.3l2.2-1.1c0 .2.1.4.1.6l-2 1-.3-.5zM109.6 326.7c0 .2 0 .5-.1.7l-2.4 1.2c0-.2-.1-.4-.1-.6l2.6-1.3z"
                                    />
                                </g>
                                <g>
                                    <path
                                        className="light-green"
                                        d="M99.2 328.6l-2.6 1.3c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3L99 328c0 .2.1.4.2.6zM102.2 326.5l.3.6-2.8 1.3-.3-.6 2.8-1.3zM105.6 325.5l-2.6 1.2-.3-.6 2.6-1.2.3.6zM108.9 323.6c0 .1.1.2.1.3l-2.8 1.4-.3-.6 2.8-1.4c.1.1.1.2.2.3z"
                                    />
                                </g>
                                <g>
                                    <path
                                        className="green"
                                        d="M99.3 319.8c-3.7 1.8-5.3 6.3-3.5 10.1 1.8 3.7 6.3 5.3 10.1 3.5 3.7-1.8 5.3-6.3 3.5-10.1-1.8-3.7-6.3-5.3-10.1-3.5zm-.3 12.7c-.2-.1-.4-.3-.6-.4-.8-.6-1.4-1.3-1.9-2.2 0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3-.4-1-.6-2-.6-3 0-.2 0-.5.1-.7.2-1.6 1-3.1 2.3-4.2-.1.7-.2 1.8 0 3.2 0 .2.1.4.1.6 0 .1 0 .3.1.4.1.7.4 1.6.7 2.4l.3.6c.4.8.9 1.6 1.6 2.4.1.2.3.3.4.5.7.7 1.6 1.4 2.6 2-1.7.2-3.4-.1-4.9-1zm6.4.5c-.2.1-.4.2-.7.2-1.1-.6-2.1-1.2-2.9-2l-.5-.5c-.7-.7-1.2-1.5-1.6-2.4l-.3-.6c-.3-.7-.5-1.5-.7-2.3 0-.2-.1-.4-.1-.6 0-.2-.1-.4-.1-.6-.2-1.7.1-3 .1-3.4.2-.1.4-.2.6-.4l6.2 12.6zm.5-.3l-6-12.5c.1-.1.3-.1.4-.2.3.2 1.5.8 2.8 2 .2.1.3.3.5.5l.4.4c.5.6 1 1.3 1.4 2 .1.2.2.4.3.5.4.9.7 1.8.8 2.7 0 .2.1.4.1.6.1 1.1 0 2.3-.2 3.6-.2.2-.3.3-.5.4zm3.7-6c0 .2 0 .5-.1.7-.2 1.7-1.1 3.4-2.5 4.5.2-1.2.2-2.3.1-3.3 0-.2-.1-.4-.1-.6-.2-1-.5-1.9-.9-2.7l-.3-.6c-.4-.8-.9-1.5-1.4-2.1l-.3-.3c-.1-.2-.3-.3-.4-.5-1-1-1.9-1.6-2.6-2 1.7-.4 3.5-.1 5 .8.2.1.4.3.6.4.8.6 1.5 1.4 2 2.3 0 .1.1.2.1.3 0 .1.1.2.1.3.5.9.7 1.9.7 2.8z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>

                </svg>
                <div className="CNFtext">
                    <div className="text-inner">
                        <h1 className='CNFh1'> Oh no. </h1>
                        <h2 className='CNFh2'> The Job which your are searching for does not exist anymore. </h2>
                        <div id="home" className="CNFbutton">
                            <h3 className='CNFh3'>
                                <Link to='/careers' className='CNFlink'>CAREERS</Link>
                            </h3>                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerNotFound
