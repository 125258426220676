import React, { useEffect } from 'react';
import "../styles/Errorpage.css";
import { Link } from 'react-router-dom';

export const Errorpage = () => {
    useEffect(() => {

    }, []);

    return (
        <section className='Ctn'>

            <div className='body'>

                <div className='Title'>

                    <div className='four_1'>4</div>
                    <div className='heart'>
                        <svg
                            className='svg'
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1194.13477 1200"
                            style={{ enableBackground: "new 0 0 1194.13477 1200" }}
                            xmlSpace="preserve"
                        >
                            <defs>
                                <filter id="polycleaner" colorInterpolationFilters="sRGB">
                                    <feComponentTransfer>
                                        <feFuncA type="table" tableValues="0 0.5 1 1" />
                                    </feComponentTransfer>
                                </filter>
                            </defs>
                            <g
                                id="low-poly"
                                className="low-poly"
                                strokeWidth={0}
                                strokeLinejoin="round"
                                filter="url(#polycleaner)"
                            >
                                <g filter="url(#polycleaner)">
                                    <polygon
                                        className="st347"
                                        points="725.3847,693.75 787.8847,706.25 737.8847,631.25 		"
                                    />
                                    <polygon
                                        className="st146"
                                        points="669.1347,675 706.6347,587.5 650.3847,600 		"
                                    />
                                    {/* Blacks*/}
                                    <polygon
                                        className="st28"
                                        points="700.3847,543.75 700.3847,512.5 700.3847,506.25 706.6347,487.5 712.64435,475.48077 
			700.3847,487.5 687.8847,518.75 687.8847,555.97827 688.1347,556.25 687.8847,555.97827 687.8847,556.25 706.6347,587.5 
			719.1347,593.75 706.6347,568.75 		"
                                    />
                                    <polygon
                                        className="st30"
                                        points="737.8847,631.25 750.3847,625 737.8847,612.5 719.46362,594.07892 725.3847,618.75 		"
                                    />
                                    <polygon
                                        className="st68"
                                        points="581.6347,381.25 587.8656,393.71179 588.1347,393.75 587.8847,394.12128 587.8847,406.25 
			587.8847,412.5 594.1347,400 594.1347,381.25 588.1347,368.75 587.81702,368.88538 		"
                                    />
                                    <polygon
                                        className="st138"
                                        points="790.5163,468.42108 788.73035,467.23047 788.14758,468.71716 		"
                                    />
                                    <polygon
                                        className="st156"
                                        points="806.8822,637.62378 806.6347,637.5 800.3847,637.5 787.8847,637.5 775.3847,637.5 806.6347,650 		
			"
                                    />
                                    <polygon
                                        className="st204"
                                        points="812.8847,493.75 813.25598,494.12128 813.36676,493.80182 813.1347,493.75 831.6347,487.5 
			800.3847,475 		"
                                    />
                                    <polygon
                                        className="st227"
                                        points="456.88455,243.77081 456.80618,243.76428 456.60971,243.79999 450.38474,256.25 444.13474,268.75 
			456.63474,268.75 		"
                                    />
                                    <polygon
                                        className="st278"
                                        points="362.87363,599.8446 362.88474,600 362.90622,599.86395 		"
                                    />
                                    <polygon
                                        className="st301"
                                        points="444.13474,381.25 450.38474,393.75 450.38474,312.5 456.63474,268.75 444.13474,268.75 
			444.13474,281.25 444.13474,300 444.13474,331.25 444.13474,343.75 437.88474,350 444.13474,356.25 		"
                                    />
                                    <polygon
                                        className="st354"
                                        points="337.88474,637.5 375.38474,606.25 425.38474,556.25 444.13474,543.75 487.88474,500 
		494.13474,487.5 494.13474,475 481.63474,487.5 475.43436,499.90082 469.13474,506.25 456.63474,512.5 450.38474,518.75 
		437.83511,525.04962 362.90622,599.86395 	"
                                    />
                                    {/* Blacks*/}
                                    {/* top left */}
                                    <polygon
                                        className="st219"
                                        points="375.38474,393.75 350.63474,393.75 437.88474,350 356.63474,362.5 350.38474,393.75 
			331.63474,406.25 387.88474,393.75 		"
                                    />
                                    {/* top left */}
                                    <polygon
                                        className="st24"
                                        points="769.1347,506.25 800.3847,475 787.8847,468.75 		"
                                    />
                                    <polygon
                                        className="st25"
                                        points="719.46362,594.07892 737.8847,612.5 750.3847,625 812.8847,581.25 719.3847,593.75 		"
                                    />
                                    <polygon
                                        className="st26"
                                        points="762.8847,631.25 775.3847,637.5 812.8847,581.25 750.3847,625 		"
                                    />
                                    <polygon
                                        className="st27"
                                        points="719.3847,593.75 812.8847,581.25 762.8847,537.5 		"
                                    />
                                    <polygon
                                        className="st29"
                                        points="800.3847,531.25 769.1347,506.25 775.3847,518.75 762.8847,537.5 812.8847,581.25 775.6347,518.75 
			812.8847,581.25 831.6347,518.75 		"
                                    />
                                    <polygon
                                        className="st31"
                                        points="831.6347,556.25 831.6347,537.5 831.6347,518.75 812.8847,581.25 831.69659,568.87378 
			831.6347,568.75 		"
                                    />
                                    <polygon
                                        className="st32"
                                        points="725.3847,618.75 719.46362,594.07892 719.1347,593.75 725.3847,631.25 725.3847,662.5 
			725.3847,693.75 737.8847,631.25 		"
                                    />
                                    <polygon
                                        className="st34"
                                        points="700.3847,487.5 706.77362,426.06836 706.6347,425 619.1347,481.25 687.8847,518.75 		"
                                    />
                                    <polygon
                                        className="st35"
                                        points="800.3847,475 792.8847,470 787.8847,468.75 		"
                                    />
                                    <polygon
                                        className="st36"
                                        points="806.6347,437.5 800.3847,437.5 837.8847,462.5 831.6347,450 831.6347,437.5 837.80249,418.9967 
			812.8847,437.5 		"
                                    />
                                    <polygon
                                        className="st37"
                                        points="788.14758,468.71716 788.1347,468.75 788.14758,468.71716 787.8847,468.75 792.8847,470 
			790.5163,468.42108 		"
                                    />
                                    <polygon
                                        className="st39"
                                        points="792.8847,470 837.8847,481.25 837.8847,475 837.8847,462.5 790.5163,468.42108 		"
                                    />
                                    <polygon
                                        className="st35"
                                        points="788.73035,467.23047 790.5163,468.42108 837.8847,462.5 800.3847,437.5 		"
                                    />
                                    <polygon
                                        className="st40"
                                        points="719.3847,593.75 762.8847,537.5 750.3847,543.75 700.3847,543.75 706.6347,568.75 719.1347,593.75 
			719.46362,594.07892 		"
                                    />
                                    <polygon
                                        className="st41"
                                        points="831.6347,518.75 869.1347,506.25 813.36676,493.80182 813.25598,494.12128 825.3847,506.25 		"
                                    />
                                    <polygon
                                        className="st37"
                                        points="800.3847,531.25 813.25598,494.12128 812.8847,493.75 800.3847,475 769.1347,506.25 		"
                                    />
                                    <polygon
                                        className="st43"
                                        points="831.6347,518.75 825.3847,506.25 813.25598,494.12128 800.3847,531.25 		"
                                    />
                                    <polygon
                                        className="st8"
                                        points="544.98944,187.63501 544.8847,187.5 544.86212,187.60657 		"
                                    />
                                    <polygon
                                        className="st3"
                                        points="544.86212,187.60657 544.8847,187.5 544.755,187.58264 		"
                                    />
                                    <polygon
                                        className="st45"
                                        points="481.88474,175 519.1347,175 512.92609,143.95697 481.63474,175 		"
                                    />
                                    <polygon
                                        className="st46"
                                        points="512.92609,143.95697 512.8847,143.75 388.49423,199.83789 481.60257,175.07507 481.63474,175 		"
                                    />
                                    <polygon
                                        className="st47"
                                        points="544.8847,187.5 544.61804,187.55206 544.755,187.58264 		"
                                    />
                                    <polygon
                                        className="st47"
                                        points="545.34473,187.71429 544.8847,187.5 544.98944,187.63501 		"
                                    />
                                    <polygon
                                        className="st48"
                                        points="619.1347,143.75 587.8847,143.75 600.3847,162.5 		"
                                    />
                                    <polygon
                                        className="st49"
                                        points="587.8847,143.75 600.3847,200 600.3847,162.5 		"
                                    />
                                    <polygon
                                        className="st50"
                                        points="619.1347,187.5 612.8847,175 600.3847,162.5 600.3847,200 619.1347,193.75 		"
                                    />
                                    <polygon
                                        className="st51"
                                        points="569.1347,218.75 625.30682,224.96375 545.34473,187.71429 544.98944,187.63501 		"
                                    />
                                    <polygon
                                        className="st52"
                                        points="625.36481,224.97015 612.8847,206.25 600.6347,200 612.8847,206.25 619.1347,193.75 600.3847,200 
			545.34473,187.71429 625.30682,224.96375 		"
                                    />
                                    <polygon
                                        className="st8"
                                        points="456.88474,243.75 456.88455,243.77081 531.6347,250 544.86212,187.60657 544.755,187.58264 
			456.63474,243.75 456.80618,243.76428 		"
                                    />
                                    <polygon
                                        className="st53"
                                        points="494.13474,200 512.8847,193.75 481.71152,175.04608 481.60257,175.07507 462.88474,218.75 
			481.63474,206.25 		"
                                    />
                                    <polygon
                                        className="st54"
                                        points="388.49423,199.83789 388.13474,200 388.49423,199.83789 387.88474,200 462.88474,218.75 
			481.60257,175.07507 		"
                                    />
                                    <polygon
                                        className="st55"
                                        points="544.61804,187.55206 512.8847,193.75 494.13474,200 481.63474,206.25 462.88474,218.75 
			456.63474,243.75 544.755,187.58264 		"
                                    />
                                    <polygon
                                        className="st56"
                                        points="481.60257,175.07507 481.71152,175.04608 481.63474,175 		"
                                    />
                                    <polygon
                                        className="st57"
                                        points="512.8847,143.75 519.1347,112.5 387.88474,200 388.49423,199.83789 		"
                                    />
                                    <polygon
                                        className="st58"
                                        points="612.8847,175 619.1347,156.25 619.1347,143.75 600.3847,162.5 		"
                                    />
                                    <polygon
                                        className="st59"
                                        points="481.63474,175 481.71152,175.04608 481.88474,175 		"
                                    />
                                    <polygon
                                        className="st60"
                                        points="481.88474,175 481.71152,175.04608 512.8847,193.75 519.1347,175 		"
                                    />
                                    <polygon
                                        className="st61"
                                        points="512.8847,193.75 525.3847,181.25 531.6347,181.25 519.1347,175 		"
                                    />
                                    <polygon
                                        className="st62"
                                        points="512.8847,193.75 544.61804,187.55206 544.3847,187.5 544.15704,187.38837 544.1347,187.5 
			544.09979,187.36035 531.6347,181.25 525.3847,181.25 		"
                                    />
                                    <polygon
                                        className="st63"
                                        points="512.92609,143.95697 513.1347,143.75 512.92609,143.95697 519.1347,175 531.6347,181.25 
			531.6347,175 531.6347,150 531.6347,137.5 525.3847,118.75 519.1347,112.5 512.8847,143.75 		"
                                    />
                                    <polygon
                                        className="st64"
                                        points="581.6347,381.25 587.81702,368.88538 544.1347,387.5 587.8656,393.71179 		"
                                    />
                                    <polygon
                                        className="st65"
                                        points="600.3847,400 587.8847,412.5 575.3847,425 687.8847,393.75 594.1347,400 		"
                                    />
                                    <polygon
                                        className="st66"
                                        points="687.8847,393.75 594.1347,381.25 594.1347,400 		"
                                    />
                                    <polygon
                                        className="st67"
                                        points="619.1347,187.5 619.1347,156.25 612.8847,175 		"
                                    />
                                    <polygon
                                        className="st68"
                                        points="581.6347,381.25 587.8656,393.71179 588.1347,393.75 587.8847,394.12128 587.8847,406.25 
			587.8847,412.5 594.1347,400 594.1347,381.25 588.1347,368.75 587.81702,368.88538 		"
                                    />
                                    <polygon
                                        className="st69"
                                        points="587.81702,368.88538 588.1347,368.75 587.8847,368.58551 587.8847,368.75 		"
                                    />
                                    <polygon
                                        className="st16"
                                        points="587.8847,393.75 587.8656,393.71179 544.1347,387.5 562.8847,431.25 587.8847,394.12128 		"
                                    />
                                    <polygon
                                        className="st70"
                                        points="512.8847,400 562.8847,431.25 544.1347,387.5 		"
                                    />
                                    <polygon
                                        className="st71"
                                        points="587.8847,406.25 587.8847,394.12128 562.8847,431.25 575.3847,425 587.8847,412.5 		"
                                    />
                                    <polygon
                                        className="st72"
                                        points="481.63474,431.25 494.32669,375.25598 494.19528,375.08075 450.38474,393.75 462.88474,412.5 
			481.52878,437.35876 481.88474,437.5 562.8847,431.25 512.8847,400 		"
                                    />
                                    <polygon
                                        className="st73"
                                        points="587.8847,394.12128 588.1347,393.75 587.8656,393.71179 587.8847,393.75 		"
                                    />
                                    <polygon
                                        className="st69"
                                        points="587.81702,368.88538 587.8847,368.75 587.8847,368.58551 569.1347,356.25 544.1347,387.5 		"
                                    />
                                    <polygon
                                        className="st74"
                                        points="594.1347,400 587.8847,412.5 600.3847,400 		"
                                    />
                                    <polygon
                                        className="st75"
                                        points="619.1347,193.75 612.8847,206.25 625.36481,224.97015 625.37903,224.97174 		"
                                    />
                                    <polygon
                                        className="st76"
                                        points="625.30682,224.96375 625.3847,225 625.36481,224.97015 		"
                                    />
                                    <polygon
                                        className="st76"
                                        points="625.36481,224.97015 625.3847,225 625.37903,224.97174 		"
                                    />
                                    <polygon
                                        className="st76"
                                        points="625.3847,225 625.39142,224.97308 625.37903,224.97174 		"
                                    />
                                    <polygon
                                        className="st77"
                                        points="619.1347,193.75 625.37903,224.97174 625.39142,224.97308 637.8847,175 619.42572,156.29156 
			619.1347,156.25 619.1347,187.5 		"
                                    />
                                    <polygon
                                        className="st78"
                                        points="494.32706,331.15387 494.13474,331.25 544.1347,387.5 569.1347,356.25 494.38474,331.25 		"
                                    />
                                    <polygon
                                        className="st79"
                                        points="506.63474,300 494.23471,331 494.32706,331.15387 581.6347,287.5 531.6347,250 		"
                                    />
                                    <polygon
                                        className="st80"
                                        points="494.38474,331.25 569.1347,356.25 581.6347,287.5 494.32706,331.15387 		"
                                    />
                                    <polygon
                                        className="st3"
                                        points="544.98944,187.63501 544.86212,187.60657 531.6347,250 569.1347,218.75 		"
                                    />
                                    <polygon
                                        className="st81"
                                        points="581.6347,287.5 625.3847,225 531.6347,250 		"
                                    />
                                    <polygon
                                        className="st82"
                                        points="569.1347,218.75 531.6347,250 625.3847,225 625.30682,224.96375 		"
                                    />
                                    <polygon
                                        className="st83"
                                        points="462.88474,100 462.88474,87.5 431.63474,87.5 456.42776,112.29303 456.68088,112.40765 		"
                                    />
                                    <polygon
                                        className="st84"
                                        points="456.67014,918.21881 456.63474,918.75 456.83786,918.63391 		"
                                    />
                                    <polygon
                                        className="st84"
                                        points="456.83786,918.63391 500.38474,893.75 487.88474,875 475.38474,850 462.88474,825 
			456.67014,918.21881 		"
                                    />
                                    <polygon
                                        className="st85"
                                        points="375.38474,793.75 369.13474,812.5 462.88474,825 		"
                                    />
                                    <polygon
                                        className="st86"
                                        points="431.63474,856.25 369.13474,812.5 381.63474,825 387.88474,831.25 406.63474,856.25 
			425.38474,881.25 444.13474,906.25 456.63474,918.75 456.67014,918.21881 		"
                                    />
                                    <polygon
                                        className="st87"
                                        points="462.88474,825 369.13474,812.5 431.63474,856.25 		"
                                    />
                                    <polygon
                                        className="st88"
                                        points="456.67014,918.21881 462.88474,825 431.63474,856.25 		"
                                    />
                                    <polygon
                                        className="st89"
                                        points="450.38474,787.5 437.93185,750.14142 375.38474,793.75 462.88474,825 		"
                                    />
                                    <polygon
                                        className="st90"
                                        points="425.63474,725 424.68246,723.94666 425.38474,725 		"
                                    />
                                    <polygon
                                        className="st91"
                                        points="424.68246,723.94666 369.13474,662.5 369.13474,675 362.88474,725 425.38474,725 		"
                                    />
                                    <polygon
                                        className="st92"
                                        points="437.88474,750 425.4863,725.20312 375.38474,793.75 437.93185,750.14142 		"
                                    />
                                    <polygon
                                        className="st93"
                                        points="425.38474,725 362.88474,725 369.13474,756.25 375.38474,787.5 375.38474,793.75 
			425.4863,725.20312 		"
                                    />
                                    <polygon
                                        className="st94"
                                        points="363.13474,600 363.43039,599.45435 362.978,599.90674 		"
                                    />
                                    <polygon
                                        className="st95"
                                        points="456.63474,243.75 456.60971,243.79999 456.80618,243.76428 		"
                                    />
                                    <polygon
                                        className="st96"
                                        points="463.13474,931.25 500.38474,893.75 456.83786,918.63391 456.88474,918.75 456.83786,918.63391 
			456.63474,918.75 462.88474,931.25 475.38474,943.75 500.38474,893.75 		"
                                    />
                                    <polygon
                                        className="st97"
                                        points="456.42776,112.29303 456.63474,112.5 456.68088,112.40765 		"
                                    />
                                    <polygon
                                        className="st98"
                                        points="494.13474,112.5 519.1347,112.5 500.38474,75 469.13474,112.5 481.63474,112.5 		"
                                    />
                                    <polygon
                                        className="st99"
                                        points="706.8847,425 706.6347,425 706.77362,426.06836 		"
                                    />
                                    <polygon
                                        className="st100"
                                        points="350.63474,393.75 375.38474,393.75 387.88474,393.75 437.88474,350 		"
                                    />
                                    <polygon
                                        className="st101"
                                        points="425.4863,725.20312 425.63474,725 425.38474,725 		"
                                    />
                                    <polygon
                                        className="st102"
                                        points="281.63474,575 288.05765,661.46246 306.63474,550 		"
                                    />
                                    <polygon
                                        className="st103"
                                        points="494.13474,475 494.13474,468.75 494.13474,462.5 481.63474,487.5 		"
                                    />
                                    <polygon
                                        className="st104"
                                        points="256.88474,550 281.63474,575 306.63474,550 		"
                                    />
                                    <polygon
                                        className="st105"
                                        points="300.38474,662.5 306.63474,656.25 288.13474,662.5 		"
                                    />
                                    <polygon
                                        className="st106"
                                        points="319.13474,787.5 331.63474,806.25 350.38474,812.5 369.13474,812.5 319.45572,787.53564 		"
                                    />
                                    <polygon
                                        className="st107"
                                        points="787.8681,1075.13257 787.8847,1075 750.3847,1018.75 737.8847,1100 		"
                                    />
                                    <polygon
                                        className="st108"
                                        points="787.94788,1075.09302 787.8847,1075 787.8681,1075.13257 		"
                                    />
                                    <polygon
                                        className="st109"
                                        points="737.8847,1100 750.3847,1018.75 625.3847,1118.75 		"
                                    />
                                    <polygon
                                        className="st110"
                                        points="562.8847,1068.75 600.3847,1100 625.3847,1118.75 525.3847,1037.5 		"
                                    />
                                    <polygon
                                        className="st111"
                                        points="331.63474,818.75 325.38474,818.75 331.63474,843.75 337.88474,862.5 362.88474,825 		"
                                    />
                                    <polygon
                                        className="st112"
                                        points="831.6347,643.75 837.8847,637.5 844.1347,625 819.1347,643.75 		"
                                    />
                                    <polygon
                                        className="st113"
                                        points="337.88474,862.5 350.38474,887.5 362.88474,912.5 362.88474,825 		"
                                    />
                                    <polygon
                                        className="st114"
                                        points="581.6347,93.75 569.1347,93.75 562.8847,106.25 556.6347,125 625.3847,106.25 		"
                                    />
                                    <polygon
                                        className="st115"
                                        points="433.32584,31.20184 414.66769,25.71417 406.63474,18.75 400.38474,37.5 387.88474,81.25 
			455.27658,36.68958 		"
                                    />
                                    <polygon
                                        className="st116"
                                        points="412.88474,706.25 406.63474,693.75 400.38474,681.25 394.13474,668.75 375.63474,656.25 
			375.38474,656.25 369.13474,662.5 424.68246,723.94666 		"
                                    />
                                    <polygon
                                        className="st117"
                                        points="694.1347,331.25 700.3847,325 706.6347,312.5 706.6347,293.75 700.52826,269.3241 687.8847,337.5 
					"
                                    />
                                    <polygon
                                        className="st118"
                                        points="669.1347,143.75 675.3847,137.5 675.3847,131.25 662.8847,125 662.8847,162.5 		"
                                    />
                                    <polygon
                                        className="st119"
                                        points="456.63474,931.25 400.38474,962.5 475.38474,943.75 		"
                                    />
                                    <polygon
                                        className="st120"
                                        points="319.45572,787.53564 369.13474,812.5 375.38474,793.75 		"
                                    />
                                    <polygon
                                        className="st121"
                                        points="556.92896,125.02673 587.8847,143.75 625.3847,131.25 		"
                                    />
                                    <polygon
                                        className="st122"
                                        points="394.13474,662.5 387.88474,656.25 375.63474,656.25 394.13474,668.75 		"
                                    />
                                    <polygon
                                        className="st123"
                                        points="356.63474,681.25 362.88474,650 369.13474,631.25 362.88474,643.75 344.13474,668.75 
			331.63474,693.75 325.38474,712.5 356.63474,725 356.63474,700 		"
                                    />
                                    <polygon
                                        className="st124"
                                        points="400.38474,900 369.13474,931.25 456.63474,931.25 		"
                                    />
                                    <polygon
                                        className="st125"
                                        points="369.13474,931.25 381.63474,943.75 400.38474,962.5 456.63474,931.25 		"
                                    />
                                    <polygon
                                        className="st126"
                                        points="363.13474,912.5 400.38474,900 375.38474,831.25 362.88474,912.5 369.13474,931.25 400.38474,900 
					"
                                    />
                                    <polygon
                                        className="st127"
                                        points="456.63474,931.25 425.38474,893.75 400.38474,862.5 375.38474,831.25 400.38474,900 		"
                                    />
                                    <polygon
                                        className="st128"
                                        points="362.88474,825 362.88474,912.5 375.38474,831.25 		"
                                    />
                                    <polygon
                                        className="st129"
                                        points="494.13474,112.5 481.63474,112.5 469.13474,112.5 456.63474,118.75 387.88474,200 519.1347,112.5 
					"
                                    />
                                    <polygon
                                        className="st130"
                                        points="737.8847,500 750.57129,462.68658 750.50909,462.62439 713.1347,475 		"
                                    />
                                    <polygon
                                        className="st131"
                                        points="750.50909,462.62439 750.3847,462.5 737.8847,462.5 725.3847,468.75 713.28308,474.80078 
			713.1347,475 		"
                                    />
                                    <polygon
                                        className="st132"
                                        points="713.11945,474.88263 706.77362,426.06836 700.3847,487.5 712.64435,475.48077 712.8847,475 		"
                                    />
                                    <polygon
                                        className="st133"
                                        points="756.6347,468.75 750.57129,462.68658 737.8847,500 		"
                                    />
                                    <polygon
                                        className="st134"
                                        points="713.28308,474.80078 713.11945,474.88263 713.1347,475 		"
                                    />
                                    <polygon
                                        className="st135"
                                        points="750.60297,462.59326 750.50909,462.62439 750.57129,462.68658 		"
                                    />
                                    <polygon
                                        className="st136"
                                        points="650.3847,600 706.6347,587.5 687.8847,556.25 		"
                                    />
                                    <polygon
                                        className="st137"
                                        points="800.3847,437.5 781.6347,462.5 788.73035,467.23047 		"
                                    />
                                    <polygon
                                        className="st139"
                                        points="750.6347,462.5 750.60297,462.59326 750.8847,462.5 		"
                                    />
                                    <polygon
                                        className="st134"
                                        points="713.11945,474.88263 712.8847,475 712.64435,475.48077 713.1347,475 		"
                                    />
                                    <polygon
                                        className="st140"
                                        points="756.6347,500 769.1347,506.25 787.8847,468.75 738.1347,500 		"
                                    />
                                    <polygon
                                        className="st141"
                                        points="750.8847,462.5 781.6347,462.5 750.3847,450 		"
                                    />
                                    <polygon
                                        className="st138"
                                        points="750.60297,462.59326 750.57129,462.68658 756.6347,468.75 762.8847,468.75 775.3847,468.75 
			787.8847,468.75 788.14758,468.71716 788.73035,467.23047 781.6347,462.5 750.8847,462.5 		"
                                    />
                                    <polygon
                                        className="st142"
                                        points="781.6347,462.5 750.3847,425 750.3847,450 		"
                                    />
                                    <polygon
                                        className="st99"
                                        points="713.11945,474.88263 713.28308,474.80078 750.3847,425 706.8847,425 706.77362,426.06836 		"
                                    />
                                    <polygon
                                        className="st143"
                                        points="725.3847,468.75 737.8847,462.5 750.3847,462.5 750.50909,462.62439 750.60297,462.59326 
			750.6347,462.5 750.8847,462.5 750.3847,450 750.3847,425 713.28308,474.80078 		"
                                    />
                                    <polygon
                                        className="st135"
                                        points="762.8847,468.75 756.6347,468.75 737.8847,500 738.1347,500 787.8847,468.75 775.3847,468.75 		"
                                    />
                                    <polygon
                                        className="st144"
                                        points="737.8847,500 700.3847,506.25 725.3847,518.75 		"
                                    />
                                    <polygon
                                        className="st145"
                                        points="700.3847,506.25 700.3847,512.5 700.3847,543.75 725.3847,518.75 		"
                                    />
                                    <polygon
                                        className="st134"
                                        points="713.1347,475 712.64435,475.48077 706.6347,487.5 700.3847,506.25 737.8847,500 		"
                                    />
                                    <polygon
                                        className="st147"
                                        points="731.6347,531.25 725.3847,518.75 700.3847,543.75 750.3847,543.75 737.8847,537.5 		"
                                    />
                                    <polygon
                                        className="st148"
                                        points="625.42999,224.97736 625.39142,224.97308 625.3847,225 		"
                                    />
                                    <polygon
                                        className="st149"
                                        points="812.8847,712.5 831.6347,712.5 838.02118,700.21832 837.90271,700.02887 787.8847,706.25 		"
                                    />
                                    <polygon
                                        className="st150"
                                        points="837.90271,700.02887 837.8847,700 812.8847,662.5 806.6347,650 787.8847,706.25 		"
                                    />
                                    <polygon
                                        className="st151"
                                        points="838.1347,700 837.90271,700.02887 838.02118,700.21832 		"
                                    />
                                    <polygon
                                        className="st152"
                                        points="537.8847,837.5 562.8847,825 594.1347,806.25 525.3847,775 		"
                                    />
                                    <polygon
                                        className="st154"
                                        points="737.8847,706.25 775.3847,731.25 825.3847,756.25 856.6347,775 787.8847,706.25 725.3847,693.75 		
			"
                                    />
                                    <polygon
                                        className="st148"
                                        points="625.71997,225.22351 625.6347,225 625.3847,225 		"
                                    />
                                    <polygon
                                        className="st155"
                                        points="625.6347,225 625.42999,224.97736 625.3847,225 		"
                                    />
                                    <polygon
                                        className="st157"
                                        points="837.8847,581.25 831.69659,568.87378 812.8847,581.25 		"
                                    />
                                    <polygon
                                        className="st158"
                                        points="625.3847,131.25 587.8847,143.75 619.1347,143.75 		"
                                    />
                                    <polygon
                                        className="st159"
                                        points="631.6347,743.75 662.8847,712.5 687.8847,687.5 712.82629,668.79382 669.1347,675 		"
                                    />
                                    <polygon
                                        className="st160"
                                        points="444.38474,743.75 525.3847,775 535.80139,743.75 		"
                                    />
                                    <polygon
                                        className="st161"
                                        points="525.3847,775 631.5813,743.83905 631.6347,743.75 535.80139,743.75 		"
                                    />
                                    <polygon
                                        className="st162"
                                        points="535.80139,743.75 631.6347,743.75 550.3847,700 		"
                                    />
                                    <polygon
                                        className="st163"
                                        points="712.82629,668.79382 712.8847,668.75 706.6347,587.5 669.1347,675 		"
                                    />
                                    <polygon
                                        className="st164"
                                        points="594.1347,806.25 631.5813,743.83905 525.3847,775 		"
                                    />
                                    <polygon
                                        className="st165"
                                        points="550.3847,700 631.6347,743.75 556.85364,631.57843 		"
                                    />
                                    <polygon
                                        className="st166"
                                        points="669.1347,675 650.3847,600 600.3847,625 637.8847,656.25 		"
                                    />
                                    <polygon
                                        className="st167"
                                        points="556.8847,631.25 556.87585,631.34375 669.1347,675 637.8847,656.25 600.3847,625 556.6347,631.25 
			556.87585,631.34375 		"
                                    />
                                    <polygon
                                        className="st168"
                                        points="556.85364,631.57843 556.6347,631.25 444.55905,743.57526 550.3847,700 		"
                                    />
                                    <polygon
                                        className="st169"
                                        points="550.3847,700 444.55905,743.57526 444.38474,743.75 535.80139,743.75 		"
                                    />
                                    <polygon
                                        className="st170"
                                        points="444.13474,356.25 437.88474,350 387.88474,393.75 444.13474,381.25 		"
                                    />
                                    <polygon
                                        className="st171"
                                        points="594.3385,343.5462 606.6347,331.25 581.6347,287.5 		"
                                    />
                                    <polygon
                                        className="st172"
                                        points="625.71997,225.22351 625.3847,225 606.6347,331.25 656.6347,306.25 		"
                                    />
                                    <polygon
                                        className="st173"
                                        points="606.6347,331.25 612.8847,337.5 637.8847,343.75 650.3847,343.75 662.8847,343.75 687.8847,337.5 
			656.6347,306.25 		"
                                    />
                                    <polygon
                                        className="st174"
                                        points="569.1347,356.25 587.8847,368.58551 587.8847,356.25 594.1347,343.75 594.3385,343.5462 
			581.6347,287.5 		"
                                    />
                                    <polygon
                                        className="st1"
                                        points="656.6347,306.25 687.8847,337.5 700.52826,269.3241 700.42859,268.92566 		"
                                    />
                                    <polygon
                                        className="st175"
                                        points="594.1347,381.25 650.3847,362.5 588.1347,368.75 		"
                                    />
                                    <polygon
                                        className="st176"
                                        points="588.1347,368.75 650.3847,362.5 587.8847,356.25 587.8847,368.58551 		"
                                    />
                                    <polygon
                                        className="st177"
                                        points="805.0722,404.6875 837.8847,418.75 844.1347,412.5 850.3847,412.5 850.18628,412.40082 
			802.16028,401.77557 		"
                                    />
                                    <polygon
                                        className="st178"
                                        points="787.8847,406.25 650.3847,362.5 687.8847,393.75 		"
                                    />
                                    <polygon
                                        className="st179"
                                        points="625.71997,225.22351 656.6347,306.25 700.42859,268.92566 700.3847,268.75 700.22144,268.52142 
			644.1347,237.5 		"
                                    />
                                    <polygon
                                        className="st180"
                                        points="687.8847,393.75 650.3847,362.5 594.1347,381.25 		"
                                    />
                                    <polygon
                                        className="st181"
                                        points="794.1347,400 800.3847,400 802.16028,401.77557 850.18628,412.40082 837.8847,406.25 
			794.1347,393.75 725.3847,381.25 650.3847,362.5 787.8847,406.25 		"
                                    />
                                    <polygon
                                        className="st182"
                                        points="662.8847,162.5 662.8847,125 625.3847,131.25 		"
                                    />
                                    <polygon
                                        className="st148"
                                        points="625.71997,225.22351 644.1347,237.5 700.22144,268.52142 669.1347,225 625.6347,225 		"
                                    />
                                    <polygon
                                        className="st183"
                                        points="619.1347,143.75 619.1347,156.25 619.42572,156.29156 619.3847,156.25 619.42572,156.29156 
			662.8847,162.5 619.3847,143.75 662.8847,162.5 625.3847,131.25 		"
                                    />
                                    <polygon
                                        className="st184"
                                        points="556.8847,125 556.92896,125.02673 625.3847,131.25 625.3847,118.75 625.3847,106.25 556.6347,125 
			556.92896,125.02673 		"
                                    />
                                    <polygon
                                        className="st185"
                                        points="587.8847,143.75 556.92896,125.02673 556.6347,125 550.3847,156.25 544.35211,186.41302 
			544.6347,187.5 		"
                                    />
                                    <polygon
                                        className="st186"
                                        points="662.8847,125 631.6347,118.75 625.3847,118.75 625.3847,131.25 		"
                                    />
                                    <polygon
                                        className="st155"
                                        points="625.6347,225 669.1347,225 662.8847,206.25 625.42999,224.97736 		"
                                    />
                                    <polygon
                                        className="st76"
                                        points="625.39142,224.97308 625.42999,224.97736 662.8847,206.25 637.8847,175 		"
                                    />
                                    <polygon
                                        className="st187"
                                        points="662.8847,206.25 662.8847,193.75 662.8847,181.25 662.8847,162.62378 637.8847,175 		"
                                    />
                                    <polygon
                                        className="st188"
                                        points="637.8847,175 662.8847,162.62378 662.8847,162.5 619.42572,156.29156 		"
                                    />
                                    <polygon
                                        className="st2"
                                        points="837.8847,418.75 837.80249,418.9967 838.1347,418.75 837.80249,418.9967 831.6347,437.5 
			831.6347,450 837.8847,462.5 837.8847,475 837.8847,481.25 844.1347,487.5 850.3847,475 850.3847,450 856.6347,425 
			850.3847,412.5 844.1347,412.5 		"
                                    />
                                    <polygon
                                        className="st189"
                                        points="812.8847,581.25 844.1347,625 844.1347,618.75 844.1347,612.5 837.8847,600 837.8847,593.75 
			838.12982,593.50488 837.8847,581.25 		"
                                    />
                                    <polygon
                                        className="st190"
                                        points="775.3847,637.5 844.1347,625 812.8847,581.25 		"
                                    />
                                    <polygon
                                        className="st191"
                                        points="787.8847,637.5 800.3847,637.5 806.6347,637.5 806.8822,637.62378 806.8847,637.5 844.1347,625 
			775.3847,637.5 		"
                                    />
                                    <polygon
                                        className="st47"
                                        points="544.3847,187.5 544.61804,187.55206 544.8847,187.5 545.34473,187.71429 600.3847,200 
			587.8847,143.75 544.6347,187.5 544.35211,186.41302 544.15704,187.38837 		"
                                    />
                                    <polygon
                                        className="st194"
                                        points="869.1347,812.86768 856.6347,831.25 844.1347,856.25 800.3847,906.25 881.60394,843.96533 
			881.6347,843.75 869.1347,825 		"
                                    />
                                    <polygon
                                        className="st195"
                                        points="831.6347,712.5 812.8847,712.5 787.8847,706.25 856.6347,775 869.1347,800 875.3847,818.75 
			881.6347,843.75 875.3847,775 869.1347,750 838.02118,700.21832 		"
                                    />
                                    <polygon
                                        className="st197"
                                        points="806.8822,637.62378 819.1347,643.75 844.1347,625 806.8847,637.5 		"
                                    />
                                    <polygon
                                        className="st198"
                                        points="869.1347,550.37128 844.1347,587.5 869.1347,587.5 869.38269,587.25201 869.1347,556.25 		"
                                    />
                                    <polygon
                                        className="st199"
                                        points="875.3847,500 844.1347,493.75 869.1347,506.25 		"
                                    />
                                    <polygon
                                        className="st200"
                                        points="813.36676,493.80182 813.3847,493.75 813.36676,493.80182 869.1347,506.25 844.1347,493.75 
			831.6347,487.5 813.1347,493.75 		"
                                    />
                                    <polygon
                                        className="st201"
                                        points="869.1347,550.37128 869.3847,550 869.1347,550.37128 869.1347,556.25 869.38269,587.25201 
			875.3847,581.25 875.3847,575 881.6347,550 875.3847,543.75 869.1347,550 		"
                                    />
                                    <polygon
                                        className="st202"
                                        points="792.8847,470 800.3847,475 831.6347,487.5 844.1347,487.5 837.8847,481.25 		"
                                    />
                                    <polygon
                                        className="st203"
                                        points="869.1347,518.75 869.38141,537.2533 875.3847,531.25 881.6347,518.75 881.6347,506.25 
			875.3847,500 869.1347,506.25 		"
                                    />
                                    <polygon
                                        className="st206"
                                        points="875.3847,543.75 862.8847,537.5 869.1347,550 		"
                                    />
                                    <polygon
                                        className="st208"
                                        points="869.1347,506.25 862.8847,537.5 869.1347,537.5 869.38141,537.2533 869.1347,518.75 		"
                                    />
                                    <polygon
                                        className="st209"
                                        points="275.38474,487.5 262.88474,518.75 306.63474,550 		"
                                    />
                                    <polygon
                                        className="st210"
                                        points="369.13474,775 362.88474,750 356.63474,725 319.13474,743.75 375.38474,793.75 		"
                                    />
                                    <polygon
                                        className="st211"
                                        points="325.38474,712.5 331.63474,693.75 344.13474,668.75 362.88474,643.75 369.13474,631.25 
			362.88474,650 356.63474,681.25 356.63474,700 356.63474,725 362.88474,750 369.13474,775 375.38474,793.75 375.38474,787.5 
			369.13474,756.25 362.88474,725 369.13474,675 369.13474,662.5 375.38474,656.25 375.63474,656.25 387.88474,656.25 
			394.13474,662.5 394.13474,668.75 400.38474,681.25 406.63474,693.75 412.88474,706.25 424.68246,723.94666 425.63474,725 
			425.4863,725.20312 437.88474,750 437.93185,750.14142 438.13474,750 437.93185,750.14142 450.38474,787.5 462.88474,825 
			475.38474,850 487.88474,875 500.38474,893.75 500.38474,887.5 494.13474,875 481.63474,850 469.13474,818.75 450.38474,768.75 
			444.13474,743.75 431.63474,718.75 412.88474,687.5 400.38474,656.25 400.38474,650 381.63474,650 375.38474,650 
			381.63474,631.25 394.13474,606.25 412.88474,581.25 444.13474,556.25 469.13474,531.25 481.63474,525 494.38474,518.75 
			531.6347,575 544.1347,500 494.63474,518.75 544.1347,500 531.6347,462.5 519.1347,481.25 500.38474,512.5 494.13474,518.75 
			487.88474,500 444.13474,543.75 425.38474,556.25 406.63474,575 375.38474,606.25 356.63474,631.25 331.63474,662.5 
			319.13474,687.5 313.13474,712.5 319.13474,687.5 331.63474,662.5 356.63474,631.25 375.38474,606.25 406.63474,575 
			425.38474,556.25 444.13474,543.75 487.88474,500 494.13474,487.5 494.38474,475 494.13474,487.5 487.88474,500 494.13474,518.75 
			500.38474,512.5 519.1347,481.25 531.6347,462.5 494.13474,462.5 494.13474,468.75 494.13474,475 481.63474,487.5 
			475.43436,499.90082 475.63474,500 475.43436,499.90082 475.38474,500 469.13474,506.25 456.63474,512.5 450.38474,518.75 
			437.88474,525 437.83511,525.04962 438.13474,525 437.83511,525.04962 431.63474,531.25 425.38474,537.5 419.13474,543.75 
			400.38474,562.5 387.88474,575 375.38474,587.5 363.43039,599.45435 363.13474,600 362.978,599.90674 362.88474,600 
			350.38474,618.75 337.88474,637.5 319.13474,650 306.63474,656.25 300.38474,662.5 288.13474,662.5 287.88474,662.5 
			269.38474,643.75 287.88474,662.5 287.978,661.94031 256.63474,550 256.63474,568.75 262.88474,618.75 269.13474,643.75 
			287.88474,675 306.63474,706.25 312.88474,712.5 312.88474,725 312.88474,737.5 319.13474,743.75 319.13474,725 		"
                                    />
                                    <polygon
                                        className="st212"
                                        points="325.38474,712.5 319.13474,725 319.13474,743.75 356.63474,725 		"
                                    />
                                    <polygon
                                        className="st213"
                                        points="600.3847,1106.25 562.8847,1081.25 525.3847,1050 512.8847,1037.5 487.88474,1025 
			456.63474,1006.25 425.38474,993.75 406.63474,975 375.38474,950 375.63474,962.5 375.38474,950 406.63474,975 425.38474,993.75 
			456.63474,1006.25 487.88474,1025 512.8847,1037.5 525.3847,1050 562.8847,1081.25 600.3847,1106.25 625.3847,1118.75 
			600.3847,1100 562.8847,1068.75 525.3847,1037.5 494.13474,1018.75 475.38474,1006.25 444.13474,993.75 425.38474,981.25 
			400.38474,962.5 381.63474,943.75 369.13474,931.25 362.88474,912.5 350.38474,887.5 337.88474,862.5 331.63474,843.75 
			325.38474,818.75 319.13474,806.25 312.88474,800 319.13474,837.5 325.38474,850 331.63474,875 344.13474,906.25 
			362.88474,943.75 375.38474,962.5 387.88474,981.25 412.88474,1012.5 425.38474,1025 425.38474,1031.25 437.88474,1037.5 
			462.88474,1056.25 494.13474,1081.25 531.6347,1100 569.1347,1118.75 600.3847,1137.5 612.8847,1137.5 625.3847,1118.75 		"
                                    />
                                    <polygon
                                        className="st214"
                                        points="319.13474,743.75 312.88474,750 312.88474,775 312.88474,800 319.13474,787.5 		"
                                    />
                                    <polygon
                                        className="st215"
                                        points="288.05765,661.46246 281.63474,575 256.88474,550 256.63474,550 287.978,661.94031 		"
                                    />
                                    <polygon
                                        className="st216"
                                        points="306.88474,431.25 362.88486,405.32404 387.88474,393.75 331.63474,406.25 306.63474,431.25 
			287.88474,462.5 312.88474,462.5 		"
                                    />
                                    <polygon
                                        className="st217"
                                        points="287.88474,462.5 275.38474,487.5 306.63474,550 		"
                                    />
                                    <polygon
                                        className="st218"
                                        points="612.8847,1137.5 625.3847,1143.75 637.8847,1150 650.3847,1156.25 675.3847,1162.5 
			625.3847,1118.75 		"
                                    />
                                    <polygon
                                        className="st220"
                                        points="856.6347,831.25 762.8847,818.75 775.3847,856.25 787.8847,881.25 800.3847,906.25 
			844.1347,856.25 		"
                                    />
                                    <polygon
                                        className="st221"
                                        points="306.63474,550 262.88474,518.75 256.63474,550 256.88474,550 		"
                                    />
                                    <polygon
                                        className="st222"
                                        points="750.3847,1018.75 856.6347,962.5 750.3847,1000 		"
                                    />
                                    <polygon
                                        className="st223"
                                        points="787.94788,1075.09302 788.1347,1075 787.94788,1075.09302 800.46692,1093.5033 806.6347,1075 
			812.8847,1056.25 831.6347,1018.75 844.1347,987.5 856.6347,962.5 787.8847,1075 		"
                                    />
                                    <polygon
                                        className="st224"
                                        points="787.8847,1075 856.6347,962.5 750.3847,1018.75 		"
                                    />
                                    <polygon
                                        className="st225"
                                        points="625.3847,1118.75 675.3847,1162.5 737.8847,1100 		"
                                    />
                                    <polygon
                                        className="st226"
                                        points="800.3847,906.25 875.3847,887.5 881.60394,843.96533 		"
                                    />
                                    <polygon
                                        className="st228"
                                        points="712.8847,1162.5 731.6347,1156.25 744.1347,1150 756.6347,1143.75 762.8847,1137.5 781.6347,1125 
			737.8847,1100 		"
                                    />
                                    <polygon
                                        className="st229"
                                        points="675.3847,1162.5 687.8847,1162.5 712.8847,1162.5 737.8847,1100 		"
                                    />
                                    <polygon
                                        className="st230"
                                        points="737.8847,1100 781.6347,1125 787.8681,1075.13257 		"
                                    />
                                    <polygon
                                        className="st231"
                                        points="787.8681,1075.13257 781.6347,1125 800.3847,1093.75 800.46692,1093.5033 787.94788,1075.09302 		
			"
                                    />
                                    <polygon
                                        className="st232"
                                        points="462.88474,87.5 469.13474,75 475.38474,62.5 481.06876,49.31128 419.13474,68.75 		"
                                    />
                                    <polygon
                                        className="st233"
                                        points="481.06876,49.31128 471.19089,42.17725 455.27658,36.68958 419.13474,68.75 		"
                                    />
                                    <polygon
                                        className="st234"
                                        points="531.6347,150 531.6347,175 531.6347,181.25 544.09979,187.36035 537.8847,162.5 
			537.8847,161.53845 531.6347,137.5 		"
                                    />
                                    <polygon
                                        className="st235"
                                        points="469.13474,75 462.88474,87.5 462.88474,100 456.68088,112.40765 456.88474,112.5 
			456.68088,112.40765 456.63474,112.5 456.63474,118.75 469.13474,112.5 481.06876,49.31128 475.38474,62.5 		"
                                    />
                                    <polygon
                                        className="st236"
                                        points="462.88474,87.5 419.13474,68.75 387.88474,81.25 431.63474,87.5 		"
                                    />
                                    <polygon
                                        className="st237"
                                        points="456.42776,112.29303 387.88474,81.25 381.63474,118.75 456.63474,112.5 		"
                                    />
                                    <polygon
                                        className="st238"
                                        points="431.63474,87.5 387.88474,81.25 456.42776,112.29303 		"
                                    />
                                    <polygon
                                        className="st239"
                                        points="525.3847,118.75 531.6347,137.5 537.8847,161.53845 537.8847,125 537.92969,124.86511 
			519.1347,112.5 		"
                                    />
                                    <polygon
                                        className="st240"
                                        points="537.92969,124.86511 550.3847,87.5 556.6347,75 519.1347,112.5 		"
                                    />
                                    <polygon
                                        className="st241"
                                        points="556.6347,75 544.1347,62.5 531.6347,56.25 506.63474,50 500.63474,75 		"
                                    />
                                    <polygon
                                        className="st242"
                                        points="500.63474,75 506.63474,50 494.13474,50 481.06876,49.31128 500.38474,75 		"
                                    />
                                    <polygon
                                        className="st243"
                                        points="356.63474,300 356.63474,362.5 437.88474,350 		"
                                    />
                                    <polygon
                                        className="st244"
                                        points="456.63474,112.5 381.63474,118.75 456.63474,118.75 		"
                                    />
                                    <polygon
                                        className="st245"
                                        points="387.88474,256.25 350.38474,287.5 444.13474,268.75 		"
                                    />
                                    <polygon
                                        className="st246"
                                        points="350.38474,287.5 356.63474,300 444.13474,268.75 		"
                                    />
                                    <polygon
                                        className="st247"
                                        points="444.13474,331.25 444.13474,300 444.13474,281.25 444.13474,268.75 356.63474,300 
			444.13474,343.75 		"
                                    />
                                    <polygon
                                        className="st248"
                                        points="444.13474,343.75 356.63474,300 437.88474,350 		"
                                    />
                                    <polygon
                                        className="st249"
                                        points="350.63474,262.5 387.88474,256.25 356.63474,250 350.38474,262.5 350.38474,287.5 
			387.88474,256.25 		"
                                    />
                                    <polygon
                                        className="st95"
                                        points="444.13474,268.75 450.38474,256.25 456.60971,243.79999 388.13474,256.25 456.60971,243.79999 
			456.63474,243.75 462.88474,218.75 356.63474,250 387.88474,256.25 		"
                                    />
                                    <polygon
                                        className="st250"
                                        points="381.63474,118.75 369.13474,162.5 362.88474,200 356.63474,225 387.88474,200 		"
                                    />
                                    <polygon
                                        className="st251"
                                        points="456.63474,118.75 381.63474,118.75 387.88474,200 		"
                                    />
                                    <polygon
                                        className="st252"
                                        points="387.88474,200 356.63474,225 356.63474,250 462.88474,218.75 		"
                                    />
                                    <polygon
                                        className="st253"
                                        points="856.6347,962.5 862.8847,937.5 869.1347,912.5 875.3847,887.5 800.3847,906.25 		"
                                    />
                                    <polygon
                                        className="st254"
                                        points="287.88474,662.5 288.13474,662.5 288.02219,662.09814 		"
                                    />
                                    <polygon
                                        className="st255"
                                        points="556.6347,631.25 544.1347,606.25 531.6347,575 519.1347,587.5 		"
                                    />
                                    <polygon
                                        className="st257"
                                        points="356.63474,512.5 369.13474,425 288.09012,661.8996 288.13474,662.5 331.63474,581.25 		"
                                    />
                                    <polygon
                                        className="st254"
                                        points="287.978,661.94031 287.88474,662.5 288.02219,662.09814 		"
                                    />
                                    <polygon
                                        className="st258"
                                        points="650.3847,600 531.6347,575 600.3847,625 		"
                                    />
                                    <polygon
                                        className="st259"
                                        points="544.1347,500 531.6347,575 650.3847,600 		"
                                    />
                                    <polygon
                                        className="st260"
                                        points="437.88474,600 494.38474,518.75 419.13474,600 		"
                                    />
                                    <polygon
                                        className="st261"
                                        points="556.6347,631.25 600.3847,625 531.6347,575 544.1347,606.25 		"
                                    />
                                    <polygon
                                        className="st254"
                                        points="288.02219,662.09814 288.13474,662.5 288.09012,661.8996 		"
                                    />
                                    <polygon
                                        className="st262"
                                        points="469.13474,818.75 481.63474,850 494.13474,875 500.38474,887.5 525.3847,775 		"
                                    />
                                    <polygon
                                        className="st263"
                                        points="444.55905,743.57526 444.13474,743.75 444.38474,743.75 		"
                                    />
                                    <polygon
                                        className="st264"
                                        points="475.38474,618.75 431.90598,643.59497 431.88474,643.75 444.13474,743.75 519.1347,587.5 		"
                                    />
                                    <polygon
                                        className="st263"
                                        points="556.6347,631.25 519.1347,587.5 444.13474,743.75 444.55905,743.57526 		"
                                    />
                                    <polygon
                                        className="st265"
                                        points="444.38474,743.75 469.13474,818.75 525.3847,775 		"
                                    />
                                    <polygon
                                        className="st266"
                                        points="444.13474,743.75 450.38474,768.75 469.13474,818.75 444.38474,743.75 		"
                                    />
                                    <polygon
                                        className="st267"
                                        points="375.38474,650 381.63474,650 400.38474,650 419.13474,600 		"
                                    />
                                    <polygon
                                        className="st268"
                                        points="494.38474,518.75 481.63474,525 469.13474,531.25 444.13474,556.25 412.88474,581.25 
			394.13474,606.25 381.63474,631.25 375.38474,650 419.13474,600 		"
                                    />
                                    <polygon
                                        className="st269"
                                        points="419.13474,600 400.38474,650 437.88474,600 		"
                                    />
                                    <polygon
                                        className="st90"
                                        points="431.90598,643.59497 431.63474,643.75 400.38474,650 400.38474,656.25 412.88474,687.5 
			431.63474,718.75 444.13474,743.75 431.88474,643.75 		"
                                    />
                                    <polygon
                                        className="st270"
                                        points="431.90598,643.59497 437.88474,600 400.38474,650 431.63474,643.75 		"
                                    />
                                    <polygon
                                        className="st271"
                                        points="531.6347,575 494.38474,518.75 437.88474,600 		"
                                    />
                                    <polygon
                                        className="st272"
                                        points="381.63474,481.25 437.88474,525 412.88474,443.75 		"
                                    />
                                    <polygon
                                        className="st273"
                                        points="481.63474,487.5 494.13474,462.5 462.88474,462.5 		"
                                    />
                                    <polygon
                                        className="st274"
                                        points="456.63474,512.5 469.13474,506.25 475.38474,500 475.43436,499.90082 450.38474,487.5 		"
                                    />
                                    <polygon
                                        className="st275"
                                        points="431.63474,456.25 412.88474,443.75 450.38474,487.5 481.63474,487.5 456.63474,475 		"
                                    />
                                    <polygon
                                        className="st276"
                                        points="475.43436,499.90082 481.63474,487.5 450.38474,487.5 		"
                                    />
                                    <polygon
                                        className="st277"
                                        points="412.88474,443.75 437.88474,525 450.38474,518.75 456.63474,512.5 450.38474,487.5 		"
                                    />
                                    <polygon
                                        className="st254"
                                        points="288.13474,662.5 306.63474,656.25 319.13474,650 337.88474,637.5 331.63474,581.25 		"
                                    />
                                    <polygon
                                        className="st279"
                                        points="400.38474,531.25 437.83511,525.04962 437.88474,525 381.63474,481.25 		"
                                    />
                                    <polygon
                                        className="st280"
                                        points="363.43039,599.45435 375.38474,587.5 387.88474,575 400.38474,562.5 419.13474,543.75 
			425.38474,537.5 431.63474,531.25 437.83511,525.04962 400.38474,531.25 		"
                                    />
                                    <polygon
                                        className="st94"
                                        points="381.63474,481.25 362.90622,599.86395 362.978,599.90674 363.43039,599.45435 400.38474,531.25 		
			"
                                    />
                                    <polygon
                                        className="st281"
                                        points="331.63474,581.25 337.88474,637.5 350.38474,618.75 362.88474,600 362.87363,599.8446 		"
                                    />
                                    <polygon
                                        className="st282"
                                        points="575.3847,425 544.1347,500 619.1347,481.25 		"
                                    />
                                    <polygon
                                        className="st283"
                                        points="687.8847,555.97827 619.1347,481.25 544.1347,500 687.8847,556.25 		"
                                    />
                                    <polygon
                                        className="st284"
                                        points="562.8847,431.25 544.1347,500 575.3847,425 		"
                                    />
                                    <polygon
                                        className="st285"
                                        points="481.63474,450 475.53354,443.8988 462.88474,462.5 494.13474,462.5 487.88474,456.25 		"
                                    />
                                    <polygon
                                        className="st284"
                                        points="531.6347,462.5 544.1347,500 562.8847,431.25 		"
                                    />
                                    <polygon
                                        className="st286"
                                        points="481.52878,437.35876 450.38474,425 438.13474,418.75 450.38474,425 444.13474,381.25 
			387.88474,393.75 400.38474,400 412.88474,406.25 425.38474,412.5 437.88474,418.75 444.13474,425 456.63474,431.25 
			462.88474,437.5 475.38474,443.75 475.53354,443.8988 475.63474,443.75 475.53354,443.8988 481.63474,450 487.88474,456.25 
			494.13474,462.5 481.63474,437.5 		"
                                    />
                                    <polygon
                                        className="st287"
                                        points="481.88474,437.5 481.52878,437.35876 481.63474,437.5 494.13474,462.5 562.8847,431.25 		"
                                    />
                                    <polygon
                                        className="st288"
                                        points="494.13474,462.5 531.6347,462.5 562.8847,431.25 		"
                                    />
                                    <polygon
                                        className="st289"
                                        points="762.97089,837.71552 762.8847,837.5 712.8847,862.5 744.1347,862.5 		"
                                    />
                                    <polygon
                                        className="st290"
                                        points="412.88474,443.75 381.63474,431.25 369.13474,425 381.63474,481.25 		"
                                    />
                                    <polygon
                                        className="st291"
                                        points="362.88474,406.25 381.63474,412.5 412.88474,431.25 462.88474,462.5 444.13474,425 
			363.13474,406.25 444.13474,425 437.88474,418.75 425.38474,412.5 412.88474,406.25 400.38474,400 387.88474,393.75 
			362.88486,405.32404 306.88474,431.25 369.13474,425 		"
                                    />
                                    <polygon
                                        className="st292"
                                        points="306.88474,431.25 312.88474,462.5 369.13474,425 		"
                                    />
                                    <polygon
                                        className="st293"
                                        points="475.53354,443.8988 475.38474,443.75 462.88474,437.5 456.63474,431.25 462.88474,462.5 		"
                                    />
                                    <polygon
                                        className="st294"
                                        points="731.6347,787.5 725.3847,818.75 712.8847,862.5 762.8847,837.5 		"
                                    />
                                    <polygon
                                        className="st295"
                                        points="456.63474,431.25 444.13474,425 462.88474,462.5 		"
                                    />
                                    <polygon
                                        className="st296"
                                        points="744.1347,975 750.3847,1000 800.3847,906.25 737.8847,950 		"
                                    />
                                    <polygon
                                        className="st297"
                                        points="737.8847,725 725.3847,706.25 731.6347,731.25 731.6347,787.5 750.3847,756.25 		"
                                    />
                                    <polygon
                                        className="st298"
                                        points="756.6347,812.5 750.3847,775 750.3847,756.25 731.6347,787.5 762.8847,837.5 		"
                                    />
                                    <polygon
                                        className="st299"
                                        points="331.63474,581.25 362.87363,599.8446 356.63474,512.5 		"
                                    />
                                    <polygon
                                        className="st300"
                                        points="719.1347,893.75 725.3847,912.5 737.8847,950 800.3847,906.25 719.1347,881.25 		"
                                    />
                                    <polygon
                                        className="st302"
                                        points="856.6347,793.75 837.8847,775 819.1347,762.5 800.3847,756.25 868.8371,812.05359 		"
                                    />
                                    <polygon
                                        className="st303"
                                        points="362.88474,600 362.978,599.90674 362.90622,599.86395 		"
                                    />
                                    <polygon
                                        className="st304"
                                        points="762.8847,762.5 762.8847,781.25 762.8847,818.75 800.3847,756.25 		"
                                    />
                                    <polygon
                                        className="st305"
                                        points="869.1347,812.86768 869.1347,812.5 868.8371,812.05359 800.3847,756.25 856.6347,831.25 		"
                                    />
                                    <polygon
                                        className="st306"
                                        points="781.6347,743.75 744.1347,725 750.3847,731.25 756.6347,750 762.8847,762.5 800.3847,756.25 		"
                                    />
                                    <polygon
                                        className="st307"
                                        points="712.8847,862.5 719.1347,881.25 744.1347,862.5 		"
                                    />
                                    <polygon
                                        className="st308"
                                        points="719.1347,881.25 800.3847,906.25 744.1347,862.5 		"
                                    />
                                    <polygon
                                        className="st309"
                                        points="775.3847,868.75 762.97089,837.71552 744.1347,862.5 800.3847,906.25 787.8847,887.5 		"
                                    />
                                    <polygon
                                        className="st310"
                                        points="644.1347,881.25 556.6347,956.25 706.6347,881.25 		"
                                    />
                                    <polygon
                                        className="st311"
                                        points="538.1347,837.5 562.8847,906.25 600.3847,806.25 569.1347,825 537.8847,837.5 500.38474,893.75 
			550.3847,950 		"
                                    />
                                    <polygon
                                        className="st312"
                                        points="369.13474,425 312.88474,462.5 306.63474,550 331.63474,493.75 		"
                                    />
                                    <polygon
                                        className="st313"
                                        points="550.3847,950 556.6347,925 562.8847,906.25 538.1347,837.5 		"
                                    />
                                    <polygon
                                        className="st314"
                                        points="500.38474,893.75 475.38474,943.75 550.3847,950 		"
                                    />
                                    <polygon
                                        className="st315"
                                        points="288.09012,661.8996 288.05765,661.46246 287.978,661.94031 288.02219,662.09814 		"
                                    />
                                    <polygon
                                        className="st316"
                                        points="637.8847,768.75 656.6347,725 637.8847,750 600.3847,806.25 631.6347,800 		"
                                    />
                                    <polygon
                                        className="st317"
                                        points="475.38474,943.75 400.38474,962.5 425.38474,981.25 444.13474,993.75 475.38474,1006.25 
			494.13474,1018.75 525.3847,1037.5 550.3847,950 		"
                                    />
                                    <polygon
                                        className="st318"
                                        points="575.3847,887.5 612.8847,837.5 631.6347,800 562.8847,906.25 		"
                                    />
                                    <polygon
                                        className="st319"
                                        points="369.13474,425 331.63474,493.75 306.63474,550 288.05765,661.46246 288.09012,661.8996 		"
                                    />
                                    <polygon
                                        className="st320"
                                        points="731.6347,956.25 712.8847,900 706.6347,881.25 556.6347,956.25 750.3847,1018.75 744.1347,1006.25 
					"
                                    />
                                    <polygon
                                        className="st321"
                                        points="569.3847,912.5 644.1347,881.25 706.6347,881.25 662.8847,731.25 644.1347,787.5 619.1347,837.5 
			594.1347,875 569.1347,912.5 556.6347,956.25 644.1347,881.25 		"
                                    />
                                    <polygon
                                        className="st322"
                                        points="706.6347,868.75 706.6347,856.25 707.5686,852.51434 713.1347,681.25 712.8847,681.25 
			687.8847,700 662.8847,731.25 706.6347,881.25 		"
                                    />
                                    <polygon
                                        className="st323"
                                        points="525.3847,775 500.38474,887.5 500.38474,893.75 537.8847,837.5 		"
                                    />
                                    <polygon
                                        className="st324"
                                        points="712.8847,831.25 719.1347,793.75 719.1347,756.25 719.1347,712.5 719.1347,681.25 713.1347,681.25 
			707.5686,852.51434 		"
                                    />
                                    <polygon
                                        className="st110"
                                        points="550.3847,950 525.3847,1037.5 625.3847,1118.75 556.6347,956.25 		"
                                    />
                                    <polygon
                                        className="st325"
                                        points="381.63474,481.25 369.13474,425 356.63474,512.5 		"
                                    />
                                    <polygon
                                        className="st326"
                                        points="625.3847,1118.75 750.3847,1018.75 556.6347,956.25 		"
                                    />
                                    <polygon
                                        className="st327"
                                        points="362.87363,599.8446 362.90622,599.86395 381.63474,481.25 356.63474,512.5 		"
                                    />
                                    <polygon
                                        className="st329"
                                        points="706.6347,868.75 706.6347,881.25 707.5686,852.51434 706.6347,856.25 		"
                                    />
                                    <polygon
                                        className="st330"
                                        points="869.1347,812.86768 869.3847,812.5 868.8371,812.05359 869.1347,812.5 		"
                                    />
                                    <polygon
                                        className="st331"
                                        points="412.88474,431.25 381.63474,412.5 362.88474,406.25 369.13474,425 381.63474,431.25 
			412.88474,443.75 431.63474,456.25 456.63474,475 481.63474,487.5 462.88474,462.5 		"
                                    />
                                    <polygon
                                        className="st332"
                                        points="800.3847,437.5 806.6347,437.5 812.8847,437.5 812.8847,431.25 812.8847,418.75 806.6347,406.25 
			805.0722,404.6875 794.1347,400 787.8847,406.25 794.1347,425 		"
                                    />
                                    <polygon
                                        className="st333"
                                        points="802.16028,401.77557 794.1347,400 805.0722,404.6875 		"
                                    />
                                    <polygon
                                        className="st334"
                                        points="800.3847,400 794.1347,400 802.16028,401.77557 		"
                                    />
                                    <polygon
                                        className="st335"
                                        points="312.88474,462.5 287.88474,462.5 306.63474,550 		"
                                    />
                                    <polygon
                                        className="st336"
                                        points="455.27658,36.68958 387.88474,81.25 419.13474,68.75 		"
                                    />
                                    <polygon
                                        className="st337"
                                        points="500.38474,75 481.06876,49.31128 469.13474,112.5 		"
                                    />
                                    <polygon
                                        className="st338"
                                        points="500.38474,75 519.1347,112.5 556.6347,75 500.63474,75 		"
                                    />
                                    <polygon
                                        className="st339"
                                        points="581.6347,287.5 606.6347,331.25 625.3847,225 		"
                                    />
                                    <polygon
                                        className="st340"
                                        points="738.1347,500 737.8847,500 738.17273,500.05762 		"
                                    />
                                    <polygon
                                        className="st341"
                                        points="725.3847,518.75 750.3847,543.75 737.8847,500 		"
                                    />
                                    <polygon
                                        className="st342"
                                        points="731.6347,531.25 737.8847,537.5 750.3847,543.75 725.3847,518.75 		"
                                    />
                                    <polygon
                                        className="st343"
                                        points="762.8847,537.5 769.1347,506.25 738.17273,500.05762 		"
                                    />
                                    <polygon
                                        className="st344"
                                        points="737.8847,500 750.3847,543.75 762.8847,537.5 738.17273,500.05762 		"
                                    />
                                    <polygon
                                        className="st345"
                                        points="775.3847,518.75 769.1347,506.25 762.8847,537.5 		"
                                    />
                                    <polygon
                                        className="st340"
                                        points="738.1347,500 738.17273,500.05762 769.1347,506.25 756.6347,500 		"
                                    />
                                    <polygon
                                        className="st346"
                                        points="687.8847,556.25 544.1347,500 650.3847,600 		"
                                    />
                                    <polygon
                                        className="st348"
                                        points="556.87585,631.34375 556.6347,631.25 556.85364,631.57843 		"
                                    />
                                    <polygon
                                        className="st349"
                                        points="556.87585,631.34375 556.85364,631.57843 631.6347,743.75 669.1347,675 		"
                                    />
                                    <polygon
                                        className="st350"
                                        points="631.6347,800 600.3847,806.25 562.8847,906.25 		"
                                    />
                                    <polygon
                                        className="st351"
                                        points="319.45572,787.53564 319.38474,787.5 319.45572,787.53564 375.38474,793.75 319.13474,743.75 
			319.13474,787.5 		"
                                    />
                                    <polygon
                                        className="st352"
                                        points="800.3847,756.25 762.8847,818.75 856.6347,831.25 		"
                                    />
                                    <polygon
                                        className="st353"
                                        points="750.3847,1000 856.6347,962.5 800.3847,906.25 		"
                                    />
                                    <polygon
                                        className="st328"
                                        points="325.38474,818.75 331.63474,818.75 362.88474,825 375.38474,831.25 400.38474,862.5 
			425.38474,893.75 456.63474,931.25 475.38474,943.75 462.88474,931.25 456.63474,918.75 444.13474,906.25 425.38474,881.25 
			406.63474,856.25 387.88474,831.25 381.63474,825 369.13474,812.5 350.38474,812.5 331.63474,806.25 319.13474,787.5 
			312.88474,800 319.13474,806.25 		"
                                    />
                                    <polygon
                                        className="st328"
                                        points="869.1347,800 856.6347,775 825.3847,756.25 775.3847,731.25 737.8847,706.25 725.3847,693.75 
			725.3847,662.5 725.3847,631.25 719.1347,593.75 706.6347,587.5 712.8847,668.75 712.82629,668.79382 713.1347,668.75 
			712.82629,668.79382 687.8847,687.5 662.8847,712.5 631.6347,743.75 631.5813,743.83905 631.8847,743.75 631.5813,743.83905 
			594.1347,806.25 562.8847,825 537.8847,837.5 569.1347,825 600.3847,806.25 637.8847,750 656.6347,725 637.8847,768.75 
			631.6347,800 612.8847,837.5 575.3847,887.5 562.8847,906.25 556.6347,925 550.3847,950 556.6347,956.25 569.1347,912.5 
			594.1347,875 619.1347,837.5 644.1347,787.5 662.8847,731.25 687.8847,700 712.8847,681.25 713.1347,681.25 719.1347,681.25 
			719.1347,712.5 719.1347,756.25 719.1347,793.75 712.8847,831.25 707.5686,852.51434 706.6347,881.25 712.8847,900 
			731.6347,956.25 744.1347,1006.25 750.3847,1018.75 750.3847,1000 744.1347,975 737.8847,950 725.3847,912.5 719.1347,893.75 
			719.1347,881.25 712.8847,862.5 725.3847,818.75 731.6347,787.5 731.6347,731.25 725.3847,706.25 737.8847,725 750.3847,756.25 
			750.3847,775 756.6347,812.5 762.8847,837.5 762.97089,837.71552 763.1347,837.5 762.97089,837.71552 775.3847,868.75 
			787.8847,887.5 800.3847,906.25 787.8847,881.25 775.3847,856.25 762.8847,818.75 762.8847,781.25 762.8847,762.5 756.6347,750 
			750.3847,731.25 744.1347,725 781.6347,743.75 800.3847,756.25 819.1347,762.5 837.8847,775 856.6347,793.75 868.8371,812.05359 
			869.3847,812.5 869.1347,812.86768 869.1347,825 881.6347,843.75 875.3847,818.75 		"
                                    />
                                    <polygon
                                        className="st1"
                                        points="700.42859,268.92566 700.6347,268.75 700.22144,268.52142 700.3847,268.75 		"
                                    />
                                    <polygon
                                        className="st2"
                                        points="850.6347,412.5 850.18628,412.40082 850.3847,412.5 		"
                                    />
                                    <polygon
                                        className="st3"
                                        points="544.15704,187.38837 544.09979,187.36035 544.1347,187.5 		"
                                    />
                                    <polygon
                                        className="st4"
                                        points="700.52826,269.3241 700.6347,268.75 700.42859,268.92566 		"
                                    />
                                    <polygon
                                        className="st5"
                                        points="544.09979,187.36035 544.15704,187.38837 544.35211,186.41302 537.8847,161.53845 537.8847,162.5 		
			"
                                    />
                                    <polygon
                                        className="st6"
                                        points="494.32706,331.15387 494.23471,331 494.13474,331.25 		"
                                    />
                                    <polygon
                                        className="st7"
                                        points="619.1347,481.25 687.8847,393.75 575.3847,425 		"
                                    />
                                    <polygon
                                        className="st8"
                                        points="456.80618,243.76428 456.88455,243.77081 456.88474,243.75 		"
                                    />
                                    <polygon
                                        className="st9"
                                        points="706.6347,425 687.8847,393.75 619.1347,481.25 		"
                                    />
                                    <polygon
                                        className="st10"
                                        points="619.1347,481.25 687.8847,555.97827 687.8847,518.75 		"
                                    />
                                    <polygon
                                        className="st11"
                                        points="800.3847,437.5 794.1347,425 787.8847,406.25 781.6347,462.5 		"
                                    />
                                    <polygon
                                        className="st12"
                                        points="812.8847,418.75 837.8847,418.75 805.0722,404.6875 806.6347,406.25 		"
                                    />
                                    <polygon
                                        className="st13"
                                        points="787.8847,406.25 687.8847,393.75 750.3847,425 		"
                                    />
                                    <polygon
                                        className="st14"
                                        points="781.6347,462.5 787.8847,406.25 750.3847,425 		"
                                    />
                                    <polygon
                                        className="st15"
                                        points="706.8847,425 750.3847,425 687.8847,393.75 706.6347,425 		"
                                    />
                                    <polygon
                                        className="st16"
                                        points="494.19528,375.08075 494.32669,375.25598 494.38474,375 		"
                                    />
                                    <polygon
                                        className="st17"
                                        points="494.13474,374.29169 494.13474,331.25 494.23471,331 456.88474,268.75 		"
                                    />
                                    <polygon
                                        className="st18"
                                        points="494.13474,375 494.13474,374.29169 456.88474,268.75 456.63474,268.75 450.38474,312.5 
			450.38474,393.75 494.19528,375.08075 		"
                                    />
                                    <polygon
                                        className="st6"
                                        points="494.23471,331 506.63474,300 531.6347,250 456.88455,243.77081 456.63474,268.75 456.88474,268.75 		
			"
                                    />
                                    <polygon
                                        className="st19"
                                        points="450.38474,393.75 444.13474,381.25 450.38474,425 450.38474,412.5 		"
                                    />
                                    <polygon
                                        className="st20"
                                        points="481.63474,431.25 512.8847,400 494.32669,375.25598 		"
                                    />
                                    <polygon
                                        className="st19"
                                        points="450.38474,393.75 450.38474,412.5 450.38474,425 481.52878,437.35876 462.88474,412.5 		"
                                    />
                                    <polygon
                                        className="st21"
                                        points="494.38474,375 544.1347,387.5 494.13474,331.25 494.13474,374.29169 		"
                                    />
                                    <polygon
                                        className="st22"
                                        points="812.8847,431.25 812.8847,437.5 837.80249,418.9967 837.8847,418.75 812.8847,418.75 		"
                                    />
                                    <polygon
                                        className="st23"
                                        points="494.13474,374.29169 494.13474,375 494.19528,375.08075 494.38474,375 		"
                                    />
                                    <polygon
                                        className="st23"
                                        points="494.32669,375.25598 512.8847,400 544.1347,387.5 494.38474,375 		"
                                    />
                                    <polygon
                                        className="st74"
                                        points="594.1347,400 587.8847,412.5 600.3847,400 		"
                                    />
                                    <polygon
                                        className="st32"
                                        points="750.3847,625 762.8847,650 775.3847,637.5 762.8847,631.25 		"
                                    />
                                    <polygon
                                        className="st33"
                                        points="787.8847,706.25 775.3847,637.5 762.8847,650 		"
                                    />
                                    <polygon
                                        className="st38"
                                        points="831.6347,537.5 831.6347,556.25 831.6347,568.75 831.69659,568.87378 831.8847,568.75 
			837.8847,556.25 831.6347,518.75 		"
                                    />
                                    <polygon
                                        className="st42"
                                        points="837.8847,556.25 869.1347,506.25 831.6347,518.75 		"
                                    />
                                    <polygon
                                        className="st44"
                                        points="750.3847,625 737.8847,631.25 787.8847,706.25 762.8847,650 		"
                                    />
                                    <polygon
                                        className="st153"
                                        points="806.6347,650 775.3847,637.5 787.8847,706.25 		"
                                    />
                                    <polygon
                                        className="st192"
                                        points="838.12982,593.50488 844.1347,587.5 837.8847,581.25 		"
                                    />
                                    <polygon
                                        className="st193"
                                        points="869.1347,550 831.8847,568.75 831.69659,568.87378 837.8847,581.25 844.1347,587.5 
			869.1347,550.37128 		"
                                    />
                                    <polygon
                                        className="st205"
                                        points="837.8847,556.25 831.8847,568.75 869.1347,550 862.8847,537.5 850.3847,543.75 		"
                                    />
                                    <polygon
                                        className="st207"
                                        points="837.8847,556.25 850.3847,543.75 862.8847,537.5 869.1347,506.25 		"
                                    />
                                    <polygon
                                        className="st196"
                                        points="806.6347,650 812.8847,662.5 837.8847,700 837.90271,700.02887 838.1347,700 838.02118,700.21832 
			869.1347,750 856.6347,718.75 844.1347,693.75 825.3847,662.5 819.1347,650 819.1347,643.75 806.8822,637.62378 		"
                                    />
                                    {/* top right */}
                                    <polygon
                                        className="st256"
                                        points="431.90598,643.59497 475.38474,618.75 519.1347,587.5 531.6347,575 437.88474,600 		"
                                    />
                                </g>
                                <path className="st355" d="M363.13474,943.75" />
                                <path className="st355" d="M362.88474,943.75" />
                                <path
                                    className="st131"
                                    d="M750.3847,462.5L750.3847,462.5L750.3847,462.5z"
                                />
                                <polyline
                                    className="st133"
                                    points="749.00262,464.39771 749.79279,461.56708 752.13806,463.33813 	"
                                />
                            </g>
                        </svg></div>
                    <div className='four_2'>4</div>
                </div>

                <div className='sub_Title'>

                <h3 className='errorText'>Oops! Page Not Found.</h3>

                </div>

                <div className='sub_para'>

                <p className='errorSubText'>We're sorry, but the page you are looking for doesn't exist.Please the check the URL or return to the Homepage to find what you're looking for. </p>

                </div>

                {/* <h1 className='title'>4&nbsp;&nbsp;4</h1> */}

                <Link className='gotohome' to="/">Go to Home</Link>
            </div>
        </section>

    );
};

export default Errorpage;